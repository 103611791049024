import React from 'react';

const AsideMenu = ({ setChosenMenuItem, chosenMenuItem }) => {
	return (
		<aside className="sidenav">
			<div className="sidenav-container hidden-md">
				<button
					className={`sidenav__item btn ${
						chosenMenuItem === 'Pobrane materiały' ? '' : 'btn--outline'
					}`}
					onClick={() => setChosenMenuItem('Pobrane materiały')}
				>
					Pobrane materiały
				</button>
				<button
					className={`sidenav__item btn ${
						chosenMenuItem === 'Twoje dane' ? '' : 'btn--outline'
					}`}
					onClick={() => setChosenMenuItem('Twoje dane')}
				>
					Twoje dane
				</button>
				<button
					className={`sidenav__item btn ${
						chosenMenuItem === 'Zmiana maila' ? '' : 'btn--outline'
					}`}
					onClick={() => setChosenMenuItem('Zmiana maila')}
				>
					Zmiana maila
				</button>
				<button
					className={`sidenav__item btn ${
						chosenMenuItem === 'Zmiana hasła' ? '' : 'btn--outline'
					}`}
					onClick={() => setChosenMenuItem('Zmiana hasła')}
				>
					Zmiana hasła
				</button>
				<button
					className={`sidenav__item btn ${
						chosenMenuItem === 'Dokumenty księgowe' ? '' : 'btn--outline'
					}`}
					onClick={() => setChosenMenuItem('Dokumenty księgowe')}
				>
					Dokumenty księgowe
				</button>
				<button
					className={`sidenav__item btn ${
						chosenMenuItem === 'Dokumenty informacyjne' ? '' : 'btn--outline'
					}`}
					onClick={() => setChosenMenuItem('Dokumenty informacyjne')}
				>
					Dokumenty informacyjne
				</button>
				<button
					className={`sidenav__item btn ${
						chosenMenuItem === 'Powiadomienia' ? '' : 'btn--outline'
					}`}
					onClick={() => setChosenMenuItem('Powiadomienia')}
				>
					Powiadomienia
				</button>
				<button
					className={`sidenav__item btn ${
						chosenMenuItem === 'Korespondencja' ? '' : 'btn--outline'
					}`}
					onClick={() => setChosenMenuItem('Korespondencja')}
				>
					Korespondencja
				</button>
			</div>
		</aside>
	);
};

export default AsideMenu;
