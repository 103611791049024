import { Loader } from '@mantine/core';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { getClient } from '../../api/get';
import { authorizedPutWithId } from '../../fetchFunctions';
import useInput from '../../hooks/useInput';
import { authActions } from '../../store/slice/authSlice';

const YourData = () => {
	const queryClient = useQueryClient();
	const dispatch = useDispatch();

	const [withVat, setWithVat] = useState(false);
	const [showTooltip, setShowTooltip] = useState(false);
	const [detailedUser, setDetailedUser] = useState(null);

	const user = useSelector((state) => state.auth.user);

	const {
		value: enteredCompany,
		enteredValueChangeHandler: enteredCompanyChangeHandler,
	} = useInput((enteredValue) => enteredValue.length > 0);

	const {
		value: enteredVatNumber,
		enteredValueChangeHandler: enteredVatNumberChangeHandler,
	} = useInput((enteredValue) => enteredValue.length > 0);

	const {
		value: enteredAddress,
		enteredValueChangeHandler: enteredAddressChangeHandler,
	} = useInput((enteredValue) => enteredValue.length > 0);

	const {
		value: enteredZipCode,
		enteredValueChangeHandler: enteredZipCodeChangeHandler,
	} = useInput((enteredValue) => enteredValue.length > 0);

	const {
		value: enteredCity,
		enteredValueChangeHandler: enteredCityChangeHandler,
	} = useInput((enteredValue) => enteredValue.length > 0);

	const changeClientDetailsMutation = useMutation(authorizedPutWithId, {
		onSuccess: () => {
			setTimeout(() => {
				changeClientDetailsMutation.reset();
			}, 3000);
		},
	});

	const { data: detailedClient } = useQuery(
		['clients', user.userId],
		getClient,
		{
			onSuccess: (usr) => {
				dispatch(
					authActions.logIn({
						...user,
						userDisplayName: usr.name || '',
						userSurname: usr.surname || '',
					})
				);
				setForm({ name: usr?.name || '', surname: usr?.surname || '' });
				enteredCompanyChangeHandler(usr?.invoiceCompanyName || '');
				enteredCityChangeHandler(usr?.invoiceCity || '');
				enteredAddressChangeHandler(usr?.invoiceAddress || '');
				enteredVatNumberChangeHandler(usr?.invoiceVatNumber || '');
			},
		}
	);

	const [form, setForm] = useState({
		name: user?.userDisplayName || '',
		surname: user?.userSurname || '',
	});
	const handleFormChange = ({ target }) =>
		setForm((prev) => ({ ...prev, [target.name]: target.value }));

	const changeClientDetailsHandler = (e) => {
		e.preventDefault();
		changeClientDetailsMutation.mutate(
			{
				url: '/clients',
				id: user.userId,
				data: {
					...form,
					isInvoice: withVat ? withVat : detailedClient?.isInvoice,
					invoiceZipCode: enteredZipCode
						? enteredZipCode
						: detailedClient?.invoiceZipCode,
					invoiceCity: enteredCity ? enteredCity : detailedClient?.invoiceCity,
					invoiceAddress: enteredAddress
						? enteredAddress
						: detailedClient?.invoiceAddress,
					invoiceCompanyName: enteredCompany
						? enteredCompany
						: detailedClient?.invoiceCompanyName,
					invoiceVatNumber: enteredVatNumber
						? enteredVatNumber
						: detailedClient?.invoiceVatNumber,
				},
			},
			{
				onSuccess: () => {
					queryClient.invalidateQueries(['clients', user.userId]);
				},
			}
		);
	};

	useEffect(() => {
		setDetailedUser(detailedClient);
		setWithVat(detailedClient?.isInvoice);
	}, [detailedClient]);

	return (
		<motion.div
			initial={{ opacity: 0, y: 5 }}
			animate={{ opacity: 1, y: 0 }}
			transition={{
				default: {
					duration: 0.3,
				},
			}}
			className="white-box white-box--library"
		>
			<div className="white-box__inner">
				<div className="passwords passwords--your-data">
					<h2 className="main-title main-title--25">Twoje dane</h2>
					<div className="main-text wysiwyg">
						<p>
							Twoje dane są nam potrzebne do wystawienia Tobie rachunku za
							opłatę udzielonej Ci licencji na wykorzystanie pobranych
							materiałów. Będą one przechowywane tak długo aż wygasną udzielone
							Tobie przyzwolenia i ograniczenia wynikające z udzielonej
							licencji.
						</p>
					</div>
					{!detailedClient && (
						<div
							style={{
								height: '10rem',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<Loader size={36} />
						</div>
					)}
					{!!detailedClient && (
						<form onSubmit={changeClientDetailsHandler}>
							<div className="row">
								<div className="col-md-6">
									<div className="form__group mb-40">
										<div className="form__checkbox">
											<label className="checkbox" htmlFor="f-invoice">
												<input
													onChange={(e) => setWithVat(e.target.checked)}
													type="checkbox"
													name="f-invoice"
													id="f-invoice"
													defaultChecked={detailedUser?.isInvoice}
													className="checkbox__input js-checkbox js-invoice-checkbox"
												/>
												<span className="checkbox__text">
													<span className="checkbox__label">
														Chcę otrzymać Fakturę VAT
													</span>
												</span>
											</label>
										</div>
									</div>

									<div className="form__group">
										<div className="form__field">
											<div className="input-container">
												<input
													value={
														detailedUser?.isGroup
															? 'Konto grupowe'
															: 'Konto indywidualne'
													}
													type="text"
													placeholder="Rodzaj konta"
													className="form__input required"
													disabled
												/>
											</div>
										</div>
									</div>

									<div className="form__group">
										<div className="form__field">
											<div className="input-container">
												<input
													defaultValue={detailedUser?.insideId}
													type="email"
													name="f-email"
													placeholder="Adres e-mail"
													className="form__input required"
													disabled
													id="f-email"
												/>
											</div>
										</div>
									</div>

									<div className="form__group">
										<div className="form__field">
											<div className="input-container">
												<input
													disabled
													defaultValue={detailedUser?.nick}
													type="text"
													name="f-name"
													placeholder="Login"
													className="form__input"
													id="f-name"
												/>
												<a
													href="/"
													onMouseEnter={() => setShowTooltip(true)}
													onMouseLeave={() => setShowTooltip(false)}
													className="floating-icon js-tooltip tooltipstered"
												>
													<i className="ico ico-tooltip">
														<span className="path1"></span>
														<span className="path2"></span>
													</i>
													{showTooltip && (
														<motion.div
															initial={{ opacity: 0 }}
															animate={{ opacity: 1 }}
															transition={{
																default: {
																	duration: 0.3,
																},
															}}
															className="custom-tooltip"
														>
															Login/nazwa podana przy rejestracji.
														</motion.div>
													)}
												</a>
											</div>
										</div>
									</div>

									<div className="form__group">
										<div className="form__field">
											<span
												className="form__info"
												style={{ textAlign: 'left' }}
											>
												Musisz uzupełnić pozostałe dane zanim będziesz mógł
												dokonać pobrania.
											</span>
										</div>
									</div>
									{withVat && (
										<motion.div
											initial={{ opacity: 0, y: 5 }}
											animate={{ opacity: 1, y: 0 }}
											transition={{
												default: {
													duration: 0.3,
												},
											}}
											className="form__group"
										>
											<div className="form__field">
												<input
													defaultValue={detailedUser?.invoiceCompanyName}
													onChange={(e) => enteredCompanyChangeHandler(e)}
													type="text"
													name="f-company"
													placeholder="Nazwa pobierającego"
													className="form__input"
													id="f-company"
												/>
											</div>
										</motion.div>
									)}
									<div className="form__group">
										<div className="form__field">
											<input
												name="name"
												value={form.name}
												onChange={handleFormChange}
												type="text"
												placeholder="Imię"
												className="form__input"
											/>
										</div>
									</div>
									<div className="form__group">
										<div className="form__field">
											<input
												name="surname"
												value={form.surname}
												onChange={handleFormChange}
												type="text"
												placeholder="Nazwisko"
												className="form__input"
											/>
										</div>
									</div>
									<div className="form__group">
										<div className="form__field">
											<input
												defaultValue={detailedUser?.invoiceAddress}
												onChange={(e) => enteredAddressChangeHandler(e)}
												type="text"
												name="f-street"
												placeholder="Ulica i nr budynku"
												className="form__input"
												id="f-street"
											/>
										</div>
									</div>
									<div className="form__group">
										<div className="input-wrapper">
											<div className="form__field">
												<input
													defaultValue={detailedUser?.invoiceZipCode}
													onChange={(e) => enteredZipCodeChangeHandler(e)}
													type="text"
													name="f-postal"
													placeholder="Kod pocztowy"
													className="form__input"
													id="f-postal"
												/>
											</div>
											<div className="form__field">
												<input
													onChange={(e) => enteredCityChangeHandler(e)}
													defaultValue={detailedUser?.invoiceCity}
													type="text"
													name="f-city"
													placeholder="Miasto"
													className="form__input"
													id="f-city"
												/>
											</div>
										</div>
									</div>
									{withVat && (
										<motion.div
											initial={{ opacity: 0, y: 5 }}
											animate={{ opacity: 1, y: 0 }}
											transition={{
												default: {
													duration: 0.3,
												},
											}}
											className="form__group"
										>
											<div className="form__field">
												<input
													defaultValue={detailedUser?.invoiceVatNumber}
													onChange={(e) => enteredVatNumberChangeHandler(e)}
													type="text"
													placeholder="NIP ( format XXXXXXXXXX )"
													className="form__input required"
													id="f-nip"
												/>
											</div>
										</motion.div>
									)}
								</div>
							</div>
							<div className="row">
								<div className="col-md-5">
									<div className="form__actions">
										<button type={'submit'} className="btn btn--block">
											{changeClientDetailsMutation.isLoading ? (
												<div>
													<Loader size={12} color={`#ffffff`} />
												</div>
											) : (
												<div>Zapisz zmiany</div>
											)}
										</button>
									</div>
								</div>
							</div>
							{changeClientDetailsMutation.isSuccess && (
								<div className="row">
									<div
										className="col-md-5"
										style={{ textAlign: 'center', marginTop: 10 }}
									>
										<span style={{ color: 'green' }}>
											Zmiany zostały zapisane!
										</span>
									</div>
								</div>
							)}
						</form>
					)}
				</div>
			</div>
		</motion.div>
	);
};

export default YourData;
