import React, { useEffect, useLayoutEffect, useState } from 'react';
import Header from '../Header/Header';
import SubHeader from '../SubHeader/SubHeader';
import Footer from '../Footer/Footer';
import Unauthorized from './Unauthorized';
import Authorization from '../Authotization/Authorization';
import Main from '../../pages/Main/Main';
import { useDispatch, useSelector } from 'react-redux';
import jwt from 'jwt-decode';
import { authActions } from '../../store/slice/authSlice';
import Cookies from 'js-cookie';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import RegisterSuccess from '../Register/RegisterSuccess';
import axios from 'axios';
import Loading from '../Loading/Loading';
import { clearTokens } from '../../utils';
import SetPassword from '../PasswordReminder/SetPassword';
import SubHeaderLib from '../SubHeaderLib/SubHeader';

const Base = () => {
	const user = useSelector((state) => state.auth.user);
	const dispatch = useDispatch();

	const [chosenMenuItem, setChosenMenuItem] = useState(initialItem());

	const [unathLib, setUnathLib] = useState(null);

	const isLogin = window.location.pathname.includes('/login');

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const pin = params.get('pin');
		const email = params.get('email');

		if (pin && email) {
			setUnathLib({ pin, email });
			setChosenMenuItem('Twoja biblioteka');
		}
	}, []);

	useLayoutEffect(() => {
		if (isLogin) {
			clearTokens();
			return;
		}

		(async () => {
			const refreshToken =
				Cookies.get('refreshToken') || localStorage.getItem('refreshToken');
			if (!refreshToken) return;

			try {
				const response = await axios.post(
					`${process.env.REACT_APP_API_URL}/auth/refresh_token`,
					{ refresh_token: refreshToken }
				);

				const { token, refresh_token } = response.data;

				const decoded = jwt(token);
				if (
					!decoded?.isActive ||
					decoded?.isBlocked ||
					decoded?.accountType !== 'client'
				) {
					clearTokens();
					return;
				}

				localStorage.setItem('token', token);
				localStorage.setItem('refreshToken', refresh_token);
				Cookies.set('token', token, {
					secure: true,
					expires: 360,
					domain: '.testujemyweby.pl',
				});

				Cookies.set('refreshToken', refresh_token, {
					secure: true,
					expires: 360,
					domain: '.testujemyweby.pl',
				});

				dispatch(authActions.logIn(decoded));
			} catch (error) {
				clearTokens();
			}
		})();
	}, [dispatch]);

	if (
		(Cookies.get('refreshToken') || localStorage.getItem('refreshToken')) &&
		!user &&
		!isLogin
	)
		return <Loading />;

	return (
		<>
			<Header
				setChosenMenuItem={setChosenMenuItem}
				chosenMenuItem={chosenMenuItem}
			/>
			{!!user && (
				<>
					<SubHeader
						setChosenMenuItem={setChosenMenuItem}
						chosenMenuItem={chosenMenuItem}
					/>
					<Main
						setChosenMenuItem={setChosenMenuItem}
						chosenMenuItem={chosenMenuItem}
					/>
				</>
			)}

			{!user && !unathLib && (
				<Unauthorized>
					<BrowserRouter>
						<Routes>
							<Route index element={<Authorization />} />
							<Route path={'/login'} element={<RegisterSuccess />} />
							<Route path="set-password/:token" element={<SetPassword />} />
						</Routes>
					</BrowserRouter>
				</Unauthorized>
			)}

			{!user && unathLib && (
				<>
					<SubHeaderLib lib={unathLib} />
					<Main
						setChosenMenuItem={setChosenMenuItem}
						chosenMenuItem={chosenMenuItem}
						unathLib={unathLib}
					/>
				</>
			)}
			<Footer />
		</>
	);
};

function initialItem() {
	let item = Cookies.get('redirect');
	Cookies.remove('redirect', {
		domain: '.testujemyweby.pl',
		path: '/',
	});

	item ??= 'Pobrane materiały';

	return item;
}

export default Base;
