import { useMediaQuery } from '@mantine/hooks';
import React, { useState } from 'react';

const HamburgerMenu = ({ setChosenMenuItem, chosenMenuItem }) => {
	const [isOpen, setIsOpen] = useState(false);

	const toggleMenu = () => {
		setIsOpen(!isOpen);
	};

	const matches = useMediaQuery('(max-width: 1199px)');

	// Style dla komponentów
	const styles = {
		container: {
			position: 'relative',
			zIndex: 1000,
		},
		button: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			width: '40px',
			height: '40px',
			backgroundColor: 'transparent',
			borderRadius: '4px',
			padding: '4px',
			border: 'none',
			cursor: 'pointer',
		},
		line: {
			backgroundColor: '#364a59',
			display: 'block',
			width: '24px',
			height: '2px',
			marginBottom: '6px',
			transition: 'transform 0.3s, opacity 0.3s',
		},
		lineTop: {
			transform: isOpen ? 'rotate(45deg) translate(6px, 6px)' : 'none',
		},
		lineMiddle: {
			opacity: isOpen ? 0 : 1,
		},
		lineBottom: {
			marginBottom: 0,
			transform: isOpen ? 'rotate(-45deg) translate(6px, -6px)' : 'none',
		},
		menu: {
			position: 'absolute',
			top: '48px',
			right: '0',
			backgroundColor: '#364a59',
			width: '256px',
			boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
			borderRadius: '4px',
			transition: 'opacity 0.3s, transform 0.3s',
			opacity: isOpen ? 1 : 0,
			transform: isOpen ? 'translateX(0)' : 'translateX(100%)',
			pointerEvents: isOpen ? 'auto' : 'none',
			zIndex: 1000,
		},
		list: {
			padding: '16px',
			margin: 0,
			listStyle: 'none',
			display: 'flex',
			flexDirection: 'column',
			gap: 8,
		},
		listItem: {},
		link: {
			color: 'white',
			display: 'block',
			padding: '8px',
			textDecoration: 'none',
			borderRadius: '4px',
		},
	};

	const handleClick = (path) => () => {
		setChosenMenuItem(path);
		setIsOpen(false);
	};

	return matches ? (
		<div style={styles.container}>
			{/* Przycisk hamburger */}
			<button onClick={toggleMenu} style={styles.button} aria-label="Menu">
				<span style={{ ...styles.line, ...styles.lineTop }}></span>
				<span style={{ ...styles.line, ...styles.lineMiddle }}></span>
				<span style={{ ...styles.line, ...styles.lineBottom }}></span>
			</button>

			{/* Wysuwane menu */}
			<div style={styles.menu}>
				<ul style={styles.list}>
					<li style={styles.listItem}>
						<a
							href="#"
							style={{
								...styles.link,
								backgroundColor:
									chosenMenuItem === 'Pobrane materiały'
										? '#444'
										: 'transparent',
							}}
							onClick={handleClick('Pobrane materiały')}
						>
							Pobrane materiały
						</a>
					</li>
					<li style={styles.listItem}>
						<a
							href="#"
							style={{
								...styles.link,
								backgroundColor:
									chosenMenuItem === 'Twoje dane' ? '#444' : 'transparent',
							}}
							onClick={handleClick('Twoje dane')}
						>
							Twoje dane
						</a>
					</li>
					<li style={styles.listItem}>
						<a
							href="#"
							style={{
								...styles.link,
								backgroundColor:
									chosenMenuItem === 'Zmiana maila' ? '#444' : 'transparent',
							}}
							onClick={handleClick('Zmiana maila')}
						>
							Zmiana maila
						</a>
					</li>
					<li style={styles.listItem}>
						<a
							href="#"
							style={{
								...styles.link,
								backgroundColor:
									chosenMenuItem === 'Zmiana hasła' ? '#444' : 'transparent',
							}}
							onClick={handleClick('Zmiana hasła')}
						>
							Zmiana hasła
						</a>
					</li>
					<li style={styles.listItem}>
						<a
							href="#"
							style={{
								...styles.link,
								backgroundColor:
									chosenMenuItem === 'Pomoc' ? '#444' : 'transparent',
							}}
							onClick={handleClick('Pomoc')}
						>
							Pomoc
						</a>
					</li>
					<li style={styles.listItem}>
						<a
							href="#"
							style={{
								...styles.link,
								backgroundColor:
									chosenMenuItem === 'Dokumenty księgowe'
										? '#444'
										: 'transparent',
							}}
							onClick={handleClick('Dokumenty księgowe')}
						>
							Dokumenty księgowe
						</a>
					</li>
					<li style={styles.listItem}>
						<a
							href="#"
							style={{
								...styles.link,
								backgroundColor:
									chosenMenuItem === 'Dokumenty informacyjne'
										? '#444'
										: 'transparent',
							}}
							onClick={handleClick('Dokumenty informacyjne')}
						>
							Dokumenty informacyjne
						</a>
					</li>
					<li style={styles.listItem}>
						<a
							href="#"
							style={{
								...styles.link,
								backgroundColor:
									chosenMenuItem === 'Powiadomienia' ? '#444' : 'transparent',
							}}
							onClick={handleClick('Powiadomienia')}
						>
							Powiadomienia
						</a>
					</li>
					<li style={styles.listItem}>
						<a
							href="#"
							style={{
								...styles.link,
								backgroundColor:
									chosenMenuItem === 'Korespondencja' ? '#444' : 'transparent',
							}}
							onClick={handleClick('Korespondencja')}
						>
							Korespondencja
						</a>
					</li>
				</ul>
			</div>
		</div>
	) : null;
};

export default HamburgerMenu;
